import { useLazyQuery } from "@apollo/client";
import { DateFormats, EventMetrics, ExportTarget } from "@technis/shared";
import { FilledButton, Size, ToastType } from "@technis/ui";
import React, { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useTracking } from "@hooks/useTracking";

import { formatDate } from "@common/helpers/date";
import { isFormDataValid } from "@common/helpers/validation";

import { translation } from "@lang/translation";

import { checkValidationState } from "@redux/formValidation/formValidation.slice";
import { displayToast, displayToastError } from "@redux/toast/toast.slice";

import { EXPORT_DATA, type ExportDataQueryPayload } from "@services/exportDataServise";

import { type RootState } from "@store/rootReducer";

export const ExportDataActions: FC = () => {
  const { t } = useTranslation();
  const { data: exportData } = useSelector((state: RootState) => state.exportData);
  const { validationFields } = useSelector((state: RootState) => state.formValidation);
  const dispatch = useDispatch();
  const { trackClick, trackingEvents } = useTracking();

  const getPayloadDateFormat = (date: number | undefined): string => formatDate(Number(date), DateFormats.UNIVERSAL);

  const [sendExport, { loading: isLoading }] = useLazyQuery<ExportDataQueryPayload>(EXPORT_DATA);

  const handleSave = async (): Promise<void> => {
    dispatch(checkValidationState());

    if (!isFormDataValid(validationFields)) {
      return;
    }

    const exportDataQueryPayload: ExportDataQueryPayload = {
      dataType: exportData.metric || EventMetrics.COUNTING,
      granularity: exportData.granularity?.value || 15,
      includeSubZones: Boolean(exportData.isSubzonesIncluded),
      userIds: exportData.recipients.map(({ id }) => String(id)),
      zoneIds: [Number(exportData.zoneId)],
      installationId: Number(exportData.installationId),
      target: exportData.shouldPassagesBeExported ? ExportTarget.PASSAGE : ExportTarget.ZONE,
      from: getPayloadDateFormat(exportData.timespan?.dateBegin),
      to: getPayloadDateFormat(exportData.timespan?.dateEnd),
    };

    try {
      trackClick({
        name: trackingEvents.EXPORT_CLICKED,
        exportDatatype: exportDataQueryPayload.dataType,
        granularity: exportDataQueryPayload.granularity,
        numberOfRecipients: exportDataQueryPayload.userIds.length,
        timeSpanOption: [exportDataQueryPayload.from, exportDataQueryPayload.to],
      });

      await sendExport({
        variables: exportDataQueryPayload,
      });
      dispatch(displayToast({ text: t(translation.toast.dataExportSuccess), type: ToastType.SUCCESS }));
    } catch {
      dispatch(displayToastError(t(translation.toast.dataExportError)));
    }
  };

  return <FilledButton size={Size.MEDIUM} text={t(translation.exportData.actions.save)} loading={isLoading} onClick={handleSave} />;
};
