import { type EventMetrics, Granularities } from "@technis/shared";
import { type DropdownElementProps } from "@technis/ui";
import React, { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useTracking } from "@hooks/useTracking";

import { getTimespanOptions } from "@common/configs";
import { getGranularityOptions } from "@common/configs/granularity/getGranularityOptions";
import { TimeRange } from "@common/enums/period";

import { PageOptions } from "@components/Options";

import { translation } from "@lang/translation";

import { updateExportData } from "@redux/exportData/exportData.slice";

import { RoutePath } from "@routes/routePath";

import { type RootState } from "@store/rootReducer";

interface Props {
  isHidden?: boolean;
  metric: EventMetrics;
}

export const InstallationPageOptions: FC<Props> = ({ metric, isHidden = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { installationId } = useParams();
  const dispatch = useDispatch();
  const installationCreationDate = useSelector((state: RootState) => state.installation.creationDate);
  const { trackClick, trackingEvents } = useTracking();

  const handleExportData = (): void => {
    dispatch(
      updateExportData({
        timespan: getTimespanOptions({ timespan: TimeRange.TODAY, translate: t })[0],
        installationId: Number(installationId),
        metric,
        granularity: getGranularityOptions({ t })[Granularities.MINUTES_15],
        installationCreationDate: installationCreationDate ? new Date(installationCreationDate) : undefined,
      }),
    );

    trackClick({ name: trackingEvents.EXPORT_INSTALLATION });

    navigate(RoutePath.EXPORT_DATA);
  };

  const options: DropdownElementProps[] = [
    {
      caption: t(translation.exportData.navigation.caption),
      onClick: handleExportData,
    },
  ];

  if (isHidden) {
    return null;
  }

  return <PageOptions options={options} />;
};
