import isFunction from "lodash/isFunction";
import React, { type FC, type PropsWithChildren, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RegularModal } from "@components/RegularModal/RegularModal";

import { updateExitModalState } from "@redux/exitModal/exitModal.slice";

import { type RootState } from "@store/rootReducer";

interface ExitPromptProps extends PropsWithChildren {
  approveButtonText: string;
  isDiscardButtonShown?: boolean;
  isFieldChanged: boolean;
  onSaveButtonClick?: (handleNavigate?: VoidFunction) => void;
  text: string;
  title: string;
}

export const ExitPrompt: FC<ExitPromptProps> = ({
  children,
  title,
  text,
  approveButtonText,
  isFieldChanged = false,
  onSaveButtonClick,
  isDiscardButtonShown,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isExitModalShown, navigationPath, isExitModalRendered } = useSelector((state: RootState) => state.exitModal);

  const updateIsExitModalShown = useCallback(
    (isExitModalShown: boolean) => {
      dispatch(updateExitModalState({ isExitModalShown }));
    },
    [dispatch],
  );

  const updateIsExitModalRendered = useCallback(
    (isExitModalRendered: boolean) => {
      dispatch(updateExitModalState({ isExitModalRendered }));
    },
    [dispatch],
  );

  const setPreviousButtonRoute = (): void => {
    window.history.pushState("previous-route", document.title, window.location.href);
  };

  const handleCancelClick = useCallback(() => {
    updateIsExitModalShown(false);
    if (!navigationPath) {
      setPreviousButtonRoute();
    }
  }, [navigationPath, updateIsExitModalShown]);

  const handleNavigate = useCallback(() => {
    if (navigationPath) {
      navigate(navigationPath);
      return;
    }
    navigate(-1);
  }, [navigate, navigationPath]);

  const closeModal = useCallback(() => {
    handleCancelClick();
  }, [handleCancelClick]);

  const onDiscardButtonClick = isDiscardButtonShown ? handleNavigate : undefined;

  const handleApproveButtonClick = useCallback(() => {
    if (isFunction(onSaveButtonClick)) {
      onSaveButtonClick(handleNavigate);
      closeModal();
      return;
    }

    handleNavigate();
  }, [closeModal, handleNavigate, onSaveButtonClick]);

  const showExitPopup = (event: BeforeUnloadEvent): string => {
    event.preventDefault();
    return (event.returnValue = "");
  };

  useEffect(() => {
    if (!isExitModalRendered && isFieldChanged) {
      updateIsExitModalRendered(true);
      return;
    }
    if (!isFieldChanged) {
      updateIsExitModalRendered(false);
    }
  }, [isExitModalRendered, isFieldChanged, updateIsExitModalRendered, updateIsExitModalShown]);

  useEffect(() => {
    if (isFieldChanged) {
      window.addEventListener("beforeunload", showExitPopup);

      return () => {
        window.removeEventListener("beforeunload", showExitPopup);
      };
    }
  }, [isFieldChanged]);

  const handlePopstate = useCallback(() => {
    updateIsExitModalShown(true);
  }, [updateIsExitModalShown]);

  useEffect(() => {
    if (isFieldChanged) {
      setPreviousButtonRoute();
      window.addEventListener("popstate", handlePopstate);
    }

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [handlePopstate, isFieldChanged, updateIsExitModalShown]);

  useEffect(
    () => () => {
      dispatch(updateExitModalState({ isExitModalRendered: false, isExitModalShown: false, navigationPath: undefined }));
    },
    [dispatch, updateIsExitModalShown],
  );

  return (
    <>
      <RegularModal
        isShown={Boolean(isExitModalShown) && isFieldChanged}
        approveButtonText={approveButtonText}
        content={<>{text}</>}
        title={title}
        onApproveButtonClick={handleApproveButtonClick}
        onCancelClick={handleCancelClick}
        onDiscardButtonClick={onDiscardButtonClick}
      />
      {children}
    </>
  );
};
