import { ErrorMessages } from "@technis/ui";
import classNames from "classnames";
import React, { type FC, type ReactNode } from "react";

export interface FormGroupProps {
  children?: ReactNode;
  className?: string;
  errorMessages?: string[];
  text?: string;
}

export const FormGroup: FC<FormGroupProps> = ({ children, text, className, errorMessages }) => (
  <div>
    {text && <div className={className ? classNames("inputLabel", className) : "inputLabel"}>{text}</div>}
    {children}
    <ErrorMessages messages={errorMessages} />
  </div>
);
