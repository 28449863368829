import { AppColor, IconCommon, IconName, type InteractiveMapMarkerDatas } from "@technis/ui";
import React, { type FC } from "react";

import styles from "./styles.module.scss";

interface Props {
  marker: InteractiveMapMarkerDatas;
}

const isProduction = process.env.ENV === "production";

// This links are only for Demo-account, it will not be displayed for other accounts
const DEMO_ACCOUNT_LINKS: { title: string; href: string }[] = isProduction
  ? [
      {
        title: "Flow History",
        href: "/dashboard/45/page/187",
      },
      {
        title: "Comfort History",
        href: "/dashboard/45/page/190",
      },
      {
        title: "Electricity History",
        href: "/dashboard/45/page/191",
      },
      {
        title: "Smart Office History",
        href: "/dashboard/45/page/147",
      },
    ]
  : [
      {
        title: "HomePage",
        href: "/home",
      },
    ];

const PopupLink: FC<{ href: string; title: string }> = ({ title, href }) => (
  <div className={styles.linkWrapper}>
    <IconCommon name={IconName.BUILDING} color={AppColor.THEME_INTERACTIVE_PRIMARY_DEFAULT} />
    <a href={href}>{title}</a>
  </div>
);

export const InteractiveMapPopup: FC<Props> = ({ marker }) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <div className={styles.title}>{marker.name}</div>
      <div className={styles.subtitle}>{marker.address}</div>
    </div>
    <div className={styles.hr} />
    <div className={styles.body}>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <div className={styles.bodyTitle}>Dashboard</div>
      {DEMO_ACCOUNT_LINKS.map((link, index) => (
        <PopupLink {...link} key={index} />
      ))}
    </div>
  </div>
);
