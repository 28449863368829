// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ub8WVaAAcOy8hHqhaGX_ {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-grow: 1;
}
.Ub8WVaAAcOy8hHqhaGX_ > div:first-child {
  margin: 0;
}
.Ub8WVaAAcOy8hHqhaGX_ .pVonvIWdfX2kfM2AmaoT {
  position: absolute;
  left: 16px;
  top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/InteractiveMap/InteractiveMapWidget/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,wBAAA;EACA,oBAAA;EACA,YAAA;AACF;AACE;EACE,SAAA;AACJ;AAEE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AAAJ","sourcesContent":[".wrapper {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n  align-items: stretch;\n  flex-grow: 1;\n\n  > div:first-child {\n    margin: 0;\n  }\n\n  .popupWrapper {\n    position: absolute;\n    left: 16px;\n    top: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Ub8WVaAAcOy8hHqhaGX_`,
	"popupWrapper": `pVonvIWdfX2kfM2AmaoT`
};
export default ___CSS_LOADER_EXPORT___;
