import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { MONTH_IN_SECONDS } from "@technis/shared";
import { FilledButton, IconCommon, IconName, Size, useTheme } from "@technis/ui";
import React, { type FC, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import { translation } from "@lang/translation";

export const COOKIE_TRACKING_AUTH = "trackingAuth";

export const CookiesModal: FC = () => {
  const { t } = useTranslation();
  const themeColors = useTheme().theme.colors;
  const [cookies, setCookie] = useCookies([COOKIE_TRACKING_AUTH]);

  const [isVisible, setIsVisible] = useState(!cookies[COOKIE_TRACKING_AUTH]);

  const onAccept = (): void => {
    setIsVisible(false);
    setCookie(COOKIE_TRACKING_AUTH, true, {
      secure: true,
      maxAge: MONTH_IN_SECONDS,
    });
  };

  if (!isVisible) return null;

  return (
    <div className="cookiesModal" style={{ backgroundColor: themeColors.BASE_00 }}>
      <div className="title" style={{ color: themeColors.DARK_20 }}>
        {t(translation.cookies.title)}
      </div>
      <div className="message" style={{ color: themeColors.DARK_60 }}>
        {t(translation.cookies.message)}
      </div>
      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={<IconCommon name={IconName.CHEVRON_DOWN} />}
          aria-controls="essential-cookies-content"
          id="essential-cookies-header"
          className="summary"
        >
          <Typography
            sx={{
              width: "50%",
              fontSize: "14px",
              flexShrink: 0,
              marginLeft: "5px",
            }}
          >
            {t(translation.cookies.essentialCookiesTitle)}
          </Typography>
          <Typography sx={{ width: "50%", fontSize: "14px", textAlign: "right" }}>{t(translation.cookies.alwaysActivated)}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: themeColors.BASE_00 }}>
          {t(translation.cookies.essentialCookiesDescription)}
        </AccordionDetails>
      </Accordion>
      <div className="buttonContainer">
        <FilledButton text={t(translation.cookies.accept)} size={Size.MEDIUM} onClick={onAccept} />
      </div>
    </div>
  );
};
