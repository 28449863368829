import { type InteractiveMapMarkerDatas } from "@technis/ui";

export const MARKERS: InteractiveMapMarkerDatas[] = [
  {
    address: "6 Rue Ballu, 75009 Paris, FRANCE",
    id: "headquarters-office",
    name: "Headquarters Office",
    position: {
      lat: 48.881_728,
      lng: 2.330_871,
    },
  },
  {
    address: "65 Rue d'Avron, 75020 Paris, FRANCE",
    id: "shop-paris-maraîchers",
    name: "Shop Paris Maraîchers",
    position: {
      lat: 48.852_295,
      lng: 2.403_358,
    },
  },
  {
    address: "80 Rue de Rennes, 75006 Paris, FRANCE",
    id: "shop-paris-rennes",
    name: "Shop Paris Rennes",
    position: {
      lat: 48.850_688,
      lng: 2.330_174,
    },
  },
  {
    address: "1 Rue d'Anjou, 78000 Versailles, FRANCE",
    id: "shop-versailles",
    name: "Shop Versailles",
    position: {
      lat: 48.797_663,
      lng: 2.124_052,
    },
  },
  {
    address: "51 Rue de Mirande, 21000 Dijon, FRANCE",
    id: "shop-dijon",
    name: "Shop Dijon",
    position: {
      lat: 47.317_428,
      lng: 5.059_124,
    },
  },
  {
    address: "28 Rue Saint-Hélier, 35000 Rennes, FRANCE",
    id: "shop-rennes",
    name: "Shop Rennes",
    position: {
      lat: 48.107_133,
      lng: -1.671_274,
    },
  },
  {
    address: "52 Boulevard du Massacre, 44800 Saint-Herblain, FRANCE",
    id: "shop-nantes",
    name: "Shop Nantes",
    position: {
      lat: 47.234_058,
      lng: -1.589_506,
    },
  },
  {
    address: "178 Avenue Berthelot, 69007 Lyon, FRANCE",
    id: "shop-lyon",
    name: "Shop Lyon",
    position: {
      lat: 45.741_588,
      lng: 4.853_219,
    },
  },
  {
    address: "Rue du Pont 5, 1003 Lausanne, SWITZERLAND",
    id: "shop-lausanne",
    name: "Shop Lausanne",
    position: {
      lat: 46.520_965,
      lng: 6.633_369,
    },
  },
  {
    address: "Place de la Gare 10, 1003 Lausanne, SWITZERLAND",
    id: "lausanne-office",
    name: "Lausanne Office",
    position: {
      lat: 46.517_666,
      lng: 6.628_496,
    },
  },
  {
    address: "Rue de Vermont 33A, 1202 Genève, SWITZERLAND",
    id: "shop-genève",
    name: "Shop Genève",
    position: {
      lat: 46.218_507,
      lng: 6.137_166,
    },
  },
  {
    address: "Choriner Strasse 19, 10435 Berlin, GERMANY",
    id: "shop-berlin",
    name: "Shop Berlin",
    position: {
      lat: 52.535_494,
      lng: 13.409_343,
    },
  },
  {
    address: "Biebergasse 7, 60313 Frankfurt, GERMANY",
    id: "shop-frankfurt",
    name: "Shop Frankfurt",
    position: {
      lat: 50.114_206,
      lng: 8.677_912,
    },
  },
  {
    address: "Sonneggstrasse 42, 8006 Zürich, SWITZERLAND",
    id: "shop-zurich",
    name: "Shop Zurich",
    position: {
      lat: 47.380_451,
      lng: 8.546_102,
    },
  },
  {
    address: "120 Oxford Gardens, London W10 6LW, UNITED KINGDOM",
    id: "shop-londres",
    name: "Shop Londres",
    position: {
      lat: 51.517_246,
      lng: -0.218_175,
    },
  },
  {
    address: "190 Briscoe Lane, Manchester, UNITED KINGDOM",
    id: "shop-manchester",
    name: "Shop Manchester",
    position: {
      lat: 53.494_826,
      lng: -2.190_461,
    },
  },
  {
    address: "89 Boulevard Périer, 13008 Marseille, FRANCE",
    id: "shop-marseille",
    name: "Shop Marseille",
    position: {
      lat: 43.278_822,
      lng: 5.381_358,
    },
  },
  {
    address: "Via di S. Vitale 7, 00184 Roma RM, ITALIA",
    id: "shop-rome",
    name: "Shop Rome",
    position: {
      lat: 41.900_846,
      lng: 12.492_125,
    },
  },
];
