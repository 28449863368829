import { AppColor, type KeyIndicatorPropsV3 } from "@technis/ui";

import moment from "moment";
import { useTranslation } from "react-i18next";

import { type DatasResult } from "@hooks/useHomepageV2Datas";

import { dwellFormatter } from "@common/helpers/formatters";

import { translation } from "@lang/translation";

export const useFlowKpiPropsFromDatas = ({ flowLiveData: rawData }: DatasResult): KeyIndicatorPropsV3[] => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const dwellRawValue = (rawData?.DWELL_2?.[0].value ?? 0) * 1000;
  const dwellValue = dwellFormatter(
    moment.duration(dwellRawValue).hours(),
    moment.duration(dwellRawValue).minutes(),
    moment.duration(dwellRawValue).seconds(),
  );

  return [
    {
      description: t(translation.dashboardV2.kpis.flow.kpis.inside.description),
      unit: t(translation.dashboardV2.kpis.flow.kpis.inside.unit),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: rawData?.INSIDE_0?.[0].value ?? 0,
    },
    {
      description: t(translation.dashboardV2.kpis.flow.kpis.today.description),
      unit: t(translation.dashboardV2.kpis.flow.kpis.today.unit),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: rawData?.AFFLUENCE_IN_1?.[0].value ?? 0,
    },
    {
      description: t(translation.dashboardV2.kpis.flow.kpis.dwell.description),
      unit: t(translation.dashboardV2.kpis.flow.kpis.dwell.unit),
      value: dwellValue,
    },
  ];
};

export const useComfortKpiPropsFromDatas = ({ comfortData: rawData }: DatasResult): KeyIndicatorPropsV3[] => {
  const { t } = useTranslation();

  return [
    {
      description: t(translation.dashboardV2.kpis.comfort.kpis.good.description),
      unit: t(translation.dashboardV2.kpis.comfort.kpis.good.unit),
      value: rawData.GOOD_EQ[0].value.toString(),
      unitColor: AppColor.ACCENT_GREEN_10,
    },
    {
      description: t(translation.dashboardV2.kpis.comfort.kpis.average.description),
      unit: t(translation.dashboardV2.kpis.comfort.kpis.average.unit),
      value: rawData.AVERAGE_EQ[0].value.toString(),
      unitColor: AppColor.ACCENT_YELLOW_10,
    },
    {
      description: t(translation.dashboardV2.kpis.comfort.kpis.critical.description),
      unit: t(translation.dashboardV2.kpis.comfort.kpis.critical.unit),
      value: rawData.CRITICAL_EQ[0].value.toString(),
      unitColor: AppColor.ACCENT_RED_10,
    },
  ];
};

export const useBehaviorKpiPropsFromDatas = ({ behaviorData: rawData }: DatasResult): KeyIndicatorPropsV3[] => {
  const { t } = useTranslation();

  return [
    {
      description: t(translation.dashboardV2.kpis.behavior.kpis.product.description),
      unit: t(translation.dashboardV2.kpis.behavior.kpis.product.unit),
      value: rawData.PRODUCT[0].value.toString(),
    },
    {
      description: t(translation.dashboardV2.kpis.behavior.kpis.femaleVisitorProportion.description),
      unit: t(translation.dashboardV2.kpis.behavior.kpis.femaleVisitorProportion.unit),
      value: rawData.GENDER_FEMALE[0].value.toString(),
    },
    {
      description: t(translation.dashboardV2.kpis.behavior.kpis.captureRate.description),
      unit: t(translation.dashboardV2.kpis.behavior.kpis.captureRate.unit),
      value: rawData.CAPTURE_RATE[0].value.toString(),
    },
  ];
};

export const useEnergyKpiPropsFromDatas = ({ energyLiveData: rawData }: DatasResult): KeyIndicatorPropsV3[] => {
  const { t } = useTranslation();

  return [
    {
      description: t(translation.dashboardV2.kpis.energy.kpis.elecConsommation.description),
      unit: t(translation.dashboardV2.kpis.energy.kpis.elecConsommation.unit),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: rawData?.ELECTRICITY_CONSUMPTION_ON_DAY_0?.[0].value.toFixed(2) ?? 0,
    },
    {
      description: t(translation.dashboardV2.kpis.energy.kpis.elecProduction.description),
      unit: t(translation.dashboardV2.kpis.energy.kpis.elecProduction.unit),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: rawData?.ELECTRICITY_PRODUCTION_ON_DAY_1?.[0].value.toFixed(2) ?? 0,
    },
    {
      description: t(translation.dashboardV2.kpis.energy.kpis.waterConsommation.description),
      unit: t(translation.dashboardV2.kpis.energy.kpis.waterConsommation.unit),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: rawData?.WATER_CONSUMPTION_ON_DAY_2?.[0].value.toFixed(2) ?? 0,
    },
  ];
};
