import { type DropdownElementProps, ExportOptions, FlatIconButton, IconName, Size, useTheme } from "@technis/ui";
import React, { type FC } from "react";

import styles from "./pageOptions.module.scss";

interface Props {
  containerElementId?: string;
  minWidth?: string;
  options?: DropdownElementProps[];
}

export const PageOptions: FC<Props> = ({ options, minWidth, containerElementId }) => {
  const themeColors = useTheme().theme.colors;

  return (
    <ExportOptions options={options} containerElementId={containerElementId} minWidth={minWidth}>
      <FlatIconButton iconColor={themeColors.BASE_20} iconName={IconName.ELLIPSIS} iconSize={Size.LARGE} className={styles.button} />
    </ExportOptions>
  );
};
