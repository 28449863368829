import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import i18next, { type TFunction } from "i18next";
import React, { type FC } from "react";

import { getAuthCookie } from "@common/helpers/cookie";

export const DebugTools: FC = () => {
  const onLangChange = (): Promise<TFunction> => i18next.changeLanguage(i18next.language === "en" ? "fr" : "en");
  const onAuthTokenCopy = (): Promise<void> => navigator.clipboard.writeText(getAuthCookie());

  return (
    <SpeedDial
      ariaLabel="Debug tools"
      sx={{ position: "fixed", bottom: 16, right: 16, "& > button": { backgroundColor: "#FF6961" } }}
      icon={<SpeedDialIcon />}
    >
      <SpeedDialAction key={"changeLang"} icon={<>L</>} tooltipTitle={"CHANGE LANG"} onClick={onLangChange} />
      <SpeedDialAction key={"copyAuthToken"} icon={<>T</>} tooltipTitle={"COPY AUTH TOKEN"} onClick={onAuthTokenCopy} />
    </SpeedDial>
  );
};
