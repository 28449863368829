import { InteractiveMap, type InteractiveMapMarker, type InteractiveMapMarkerDatas } from "@technis/ui";
import React, { useState, type FC } from "react";

import { MARKERS } from "./InteractiveMapWidget.constants";
import styles from "./styles.module.scss";
import { InteractiveMapPopup } from "../InteractiveMapPopup/InteractiveMapPopup";

export const InteractiveMapWidget: FC = () => {
  const [selectedMarker, setSelectedMarker] = useState<InteractiveMapMarkerDatas | null>(null);

  const markersWithEvents: InteractiveMapMarker[] = MARKERS.map((marker) => ({
    ...marker,
    onClick: () => setSelectedMarker(marker),
  }));

  return (
    <div className={styles.wrapper}>
      <InteractiveMap
        isFlatProjection
        markers={markersWithEvents}
        mapboxAccessToken={process.env.MAPBOX_API_KEY}
        height={800}
        initialMapState={{
          latitude: 49.133_331,
          longitude: 6.166_67,
          zoom: 3,
        }}
      />
      {!!selectedMarker && (
        <div className={styles.popupWrapper}>
          <InteractiveMapPopup marker={selectedMarker} />
        </div>
      )}
    </div>
  );
};
