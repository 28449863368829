// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VlGyJowjBl_isAYu1NCA.VlGyJowjBl_isAYu1NCA {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 75px;
  margin-left: -10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/breadcrumbs.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,mBAAA;EAEA,YAAA;EACA,kBAAA;AAAF","sourcesContent":[".wrapper.wrapper {\n  display: flex;\n  flex-shrink: 0;\n  align-items: center;\n\n  height: 75px;\n  margin-left: -10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `VlGyJowjBl_isAYu1NCA`
};
export default ___CSS_LOADER_EXPORT___;
