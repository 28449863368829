import { BreadCrumbs } from "@technis/ui";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatches, useNavigate } from "react-router-dom";

import { type BreadcrumbRouteName } from "@common/enums/breadcrumbs";
import { type SideBarTab } from "@common/interfaces/sidebar/sideBarTab";

import { TRANSLATION_KEY } from "@redux/breadcrumbs/breadcrumbs.slice";
import { updateExitModalState } from "@redux/exitModal/exitModal.slice";

import { type RootState } from "@store/rootReducer";

import styles from "./breadcrumbs.module.scss";
import pageStyles from "../../../styles/page.module.scss";

interface Props {
  customPageData: SideBarTab[];
}
interface Breadcrumb {
  breadcrumb: BreadcrumbRouteName;
  params: Partial<Record<string, string>>;
  pathname: string;
}

interface Matches {
  data: unknown;
  handle: {
    breadcrumb?: BreadcrumbRouteName;
    breadcrumbChildren?: ReactNode;
  };
  id: string;
  params: Partial<Record<string, string>>;
  pathname: string;
}

export const Breadcrumbs: FC<Props> = (props) => {
  const { customPageData } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const breadcrumbsConfig = useSelector((state: RootState) => state.breadcrumbs);
  const matches = useMatches() as Matches[];
  const breadcrumbsList = matches.filter((match) => Boolean(match.handle?.breadcrumb));

  const isExitModalRendered = useSelector((state: RootState) => state.exitModal.isExitModalRendered);

  const breadcrumbs: Breadcrumb[] = breadcrumbsList.map(
    ({ pathname, params, handle }) =>
      ({
        pathname,
        params,
        breadcrumb: handle.breadcrumb,
      } as Breadcrumb),
  );

  const breadcrumbWithChildren = breadcrumbsList.find((breadcrumb) => breadcrumb.handle.breadcrumbChildren);

  const crumbs = breadcrumbs.map(({ breadcrumb, pathname, params }) => {
    const { pageId } = params;
    const customData = customPageData.find((data) => data.pageId === Number(pageId));

    const details = {
      text: customData ? customData?.text : breadcrumbsConfig[breadcrumb].text,
      icon: customData ? customData?.icon : breadcrumbsConfig[breadcrumb].icon,
    };

    const hasToBeTranslated = details.text.startsWith(TRANSLATION_KEY);
    return {
      value: hasToBeTranslated ? t(details.text.replace(TRANSLATION_KEY, "")) : details.text,
      icon: details.icon,
      to: pathname,
    };
  });

  const handleOnClick = (navigationPath: string): void => {
    if (isExitModalRendered) {
      dispatch(updateExitModalState({ isExitModalShown: true, navigationPath }));
      return;
    }
    navigate(navigationPath);
  };

  return isEmpty(crumbs) ? (
    <div className={styles.extraSpace}></div>
  ) : (
    <BreadCrumbs crumbs={crumbs} className={classNames(styles.wrapper, pageStyles.pageContainer)} onClick={handleOnClick}>
      {breadcrumbWithChildren?.handle.breadcrumbChildren}
    </BreadCrumbs>
  );
};
