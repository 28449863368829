import { MultiKeyIndicatorV3 } from "@technis/ui";
import React, { type FC } from "react";

import { useTranslation } from "react-i18next";

import { useHomepageV2Datas } from "@hooks/useHomepageV2Datas";

import { translation } from "@lang/translation";

import {
  useBehaviorKpiPropsFromDatas,
  useComfortKpiPropsFromDatas,
  useEnergyKpiPropsFromDatas,
  useFlowKpiPropsFromDatas,
} from "./HomePageV2Kpis.utils";
import styles from "./styles.module.scss";

export const HomePageV2Kpis: FC = () => {
  const { t } = useTranslation();

  const datas = useHomepageV2Datas();

  const flowKpisProps = useFlowKpiPropsFromDatas(datas);
  const comfortKpisProps = useComfortKpiPropsFromDatas(datas);
  const behaviorKpisProps = useBehaviorKpiPropsFromDatas(datas);
  const energyKpisProps = useEnergyKpiPropsFromDatas(datas);

  return (
    <div className={styles.wrapper}>
      <div className={styles.multiKpiWrapper}>
        <MultiKeyIndicatorV3
          title={t(translation.dashboardV2.kpis.flow.title)}
          tooltip={t(translation.dashboardV2.kpis.flow.tooltip)}
          kpis={flowKpisProps}
        />
      </div>
      <div className={styles.multiKpiWrapper}>
        <MultiKeyIndicatorV3
          title={t(translation.dashboardV2.kpis.comfort.title)}
          tooltip={t(translation.dashboardV2.kpis.comfort.tooltip)}
          kpis={comfortKpisProps}
        />
      </div>
      <div className={styles.multiKpiWrapper}>
        <MultiKeyIndicatorV3
          title={t(translation.dashboardV2.kpis.behavior.title)}
          tooltip={t(translation.dashboardV2.kpis.behavior.tooltip)}
          kpis={behaviorKpisProps}
        />
      </div>
      <div className={styles.multiKpiWrapper}>
        <MultiKeyIndicatorV3
          title={t(translation.dashboardV2.kpis.energy.title)}
          tooltip={t(translation.dashboardV2.kpis.energy.tooltip)}
          kpis={energyKpisProps}
        />
      </div>
    </div>
  );
};
