// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CNuVJJq9hQLwHt1loW71 {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 8px;
  margin-bottom: 8px;
}
.CNuVJJq9hQLwHt1loW71 .pgT0IcZEDNlzKPibULp1 {
  min-width: 400px;
  width: calc(50% - 4px);
  flex-grow: 1;
  flex-shrink: 1;
}
.CNuVJJq9hQLwHt1loW71 .pgT0IcZEDNlzKPibULp1 > div > div:nth-child(2) {
  flex-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/HomePageV2Kpis/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,oBAAA;EACA,QAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;AACJ;AAEI;EACE,iBAAA;AAAN","sourcesContent":[".wrapper {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: stretch;\n  gap: 8px;\n  margin-bottom: 8px;\n\n  .multiKpiWrapper {\n    min-width: 400px;\n    width: calc(50% - 4px);\n    flex-grow: 1;\n    flex-shrink: 1;\n\n    // Override component style in the homepage to force one lined kpis\n    > div > div:nth-child(2) {\n      flex-wrap: nowrap;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CNuVJJq9hQLwHt1loW71`,
	"multiKpiWrapper": `pgT0IcZEDNlzKPibULp1`
};
export default ___CSS_LOADER_EXPORT___;
