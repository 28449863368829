import { useMutation } from "@apollo/client";
import { useMediaQuery } from "@mui/material";
import { FlatButton, Variant } from "@technis/ui";
import classNames from "classnames";
import React, { memo, type ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDeepCompareEffect } from "react-use";

import { useBoolean } from "@hooks/useBoolean";

import { shouldShowReportGenerator } from "@utils/hacks";

import { startTutorial } from "@graphql/mutation/tutorial";

import { ContainerId } from "@common/enums";
import { COOKIE_AUTH_VIEW_AS_USER, getCookie } from "@common/helpers/cookie";
import { type SideBarTab } from "@common/interfaces/sidebar/sideBarTab";

import { Sidebar } from "@components/Sidebar/Sidebar";
import { TutorialSkipModal } from "@components/tutorial/TutorialSkipModal/TutorialSkipModal";
import { TutorialWelcomeModal } from "@components/tutorial/TutorialWelcomeModal/TutorialWelcomeModal";

import { translation } from "@lang/translation";

import { setIsSkipModalShown } from "@redux/tutorial/tutorial.slice";

import { RoutePath } from "@routes/routePath";

import { type ProfileData } from "@services/profileService";

import { type RootState } from "@store/rootReducer";

import sidebarStyles from "../../../../styles/sidebar.module.scss";
import { ReportGenerator } from "../../ReportGenerator";

const { PAGE, HELP_CENTER, COUNTING, AIR_QUALITY } = RoutePath;

interface MainLayoutProps {
  children?: ReactNode;
  isAirQualityShown: boolean | null;
  isCountingShown: boolean | null;
  isExportDataShown?: boolean;
  isSettingsShown: boolean;
  logo: string;
  onStartTutorial: () => void;
  organizationId?: number;
  profileData?: ProfileData;
  sidebarConfig: SideBarTab[];
}

const MainLayoutBase = ({
  sidebarConfig,
  profileData,
  isCountingShown,
  isAirQualityShown,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onStartTutorial,
  isSettingsShown,
  children,
  isExportDataShown = false,
  organizationId,
}: MainLayoutProps): JSX.Element => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isViewingAs = !!getCookie(COOKIE_AUTH_VIEW_AS_USER);

  const { value: isReportGeneratorOpened, setValue: setReportGeneratorState } = useBoolean(false);
  const [isTutorialWelcomeModalShown, setIsTutorialWelcomeModalShown] = useState(false);
  const isTutorialPage = pathname.includes(RoutePath.TUTORIAL);

  const { isSkipModalShown } = useSelector((state: RootState) => state.tutorial);
  const user = useSelector((state: RootState) => state.user);
  const [setHasTutorialStarted] = useMutation(startTutorial);

  const dispatch = useDispatch();
  const { pageId } = useParams();

  const isReportGeneratorVisible = matchPath(PAGE, pathname) && shouldShowReportGenerator(Number(pageId));

  const isOverflowHidden = isTutorialPage || [AIR_QUALITY, COUNTING].includes(pathname as RoutePath);

  const isSidebarShrink = useMediaQuery("(max-width:1000px)");

  const handleTutorialWelcomeModalShown = useCallback(async () => {
    // TODO: Add back on tutorial release
    // if (location.pathname !== RoutePath.HOME) {
    //   navigate(RoutePath.HOME);
    // }
    // if (user && !user?.hasStartedTutorial && !isViewingAs) {
    //   await setHasTutorialStarted();
    //   onStartTutorial();
    // }
    // if (!isViewingAs) {
    // setIsTutorialWelcomeModalShown(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, setHasTutorialStarted, user?.hasStartedTutorial]);

  const handleSkipModalShown = (): void => {
    dispatch(setIsSkipModalShown(true));
  };

  const handleSkipModalClose = (): void => {
    dispatch(setIsSkipModalShown(false));
    navigate(`${RoutePath.HOME}`);
  };

  const handleTutorialWelcomeModalClose = (): void => {
    setIsTutorialWelcomeModalShown(false);
    handleSkipModalShown();
  };

  const handleTutorialWelcomeModalStart = (): void => {
    handleTutorialWelcomeModalClose();
    handleSkipModalClose();
    navigate(`${RoutePath.TUTORIAL}`);
  };

  const handleSkipModalBack = async (): Promise<void> => {
    if (isTutorialPage) {
      dispatch(setIsSkipModalShown(false));
      return;
    }

    await handleTutorialWelcomeModalShown();
  };

  useDeepCompareEffect(() => {
    if (user && !user.hasStartedTutorial && !isViewingAs) {
      handleTutorialWelcomeModalShown();
    }
  }, [handleTutorialWelcomeModalShown, user]);

  const containerStyles = useMemo(
    () =>
      classNames("main", {
        overflowHidden: isOverflowHidden,
        helpCenter: pathname === HELP_CENTER,
        tutorial: isTutorialPage,
      }),
    [isOverflowHidden, isTutorialPage, pathname],
  );

  return (
    <>
      {isReportGeneratorVisible && <ReportGenerator isOpen={isReportGeneratorOpened} setIsOpen={setReportGeneratorState} />}
      <div className="wrapper">
        <Sidebar
          isSidebarShrunk={isSidebarShrink}
          sidebarConfig={sidebarConfig}
          isAirQualityShown={isAirQualityShown}
          isCountingShown={isCountingShown}
          isExportDataShown={isExportDataShown}
          email={user.email}
          firstName={`${user.firstName} ${user.lastName}`}
          avatarSrc={user?.picture}
          profileData={profileData}
          organizationId={organizationId}
          isSettingsShown={isSettingsShown}
          footerElements={
            <div className={sidebarStyles.sidebarFooter}>
              {isReportGeneratorVisible && (
                <FlatButton
                  className={sidebarStyles.element}
                  text={t(translation.common.reportGenerator)}
                  colorVariant={Variant.LIGHT}
                  onClick={(): void => {
                    setReportGeneratorState(true);
                  }}
                />
              )}
            </div>
          }
        />
        <div className={containerStyles} id={ContainerId.MainLayout}>
          {children}
          <Outlet />
        </div>
      </div>
      <TutorialWelcomeModal
        isShown={isTutorialWelcomeModalShown}
        onClose={handleTutorialWelcomeModalClose}
        onSkip={handleTutorialWelcomeModalClose}
        onStart={handleTutorialWelcomeModalStart}
      />
      <TutorialSkipModal
        isShown={isSkipModalShown}
        onClose={handleSkipModalClose}
        onSkip={handleSkipModalClose}
        onBackToTutorial={handleSkipModalBack}
      />
    </>
  );
};

export const MainLayout = memo(MainLayoutBase);
